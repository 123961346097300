<template>
  <div class="work-page right-here-right-now">
  	<!-- Header -->
  	<section class="header">

      <img v-lazy="'/static/images/RHRN_1.jpg'"/>
    
  		<div class="meta">
  			<p class="client">CITY FC</p>
  			<a class="project">RIGHT HERE RIGHT NOW</a>
  		</div>
  		<div class="copy container">
  			<div class="col-1">
  				<h1>A club that wants to own the moment.</h1>
  			</div>
  			<div class="col-2">
  				<p>Yesterday is gone. Tomorrow is not here. All we have is now. So let’s own it. The 2018/19 campaign for Melbourne City FC.</p>
  			</div>
  		</div>
  	</section>

    <div class="spacer-lg"></div>

    <div class="two-col" style="display: flex; justify-content: space-between;">
      <div class="col" style="width: 49%; margin-top: 35%;">
        <img src="/static/images/RHRN_2.jpg" style="margin-top: -65%"/>
      </div>
      <div class="col" style="width: 49%;">
        <img src="/static/images/RHRN_3.jpg" style="margin-bottom: -60%"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <div class="two-col" style="display: flex; margin-top: 15%;">
      <div class="col" style="width: 40%; z-index: 2; margin-right: -10%">
        <img src="static/images/RHRN_GIF.gif" style=""/>
      </div>
      <div class="col" style="width: 75%">
        <img src="/static/images/RHRN_5.jpg"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <div class="two-col mobile-margin-bottom no-desktop" style="display: flex; justify-content: space-between; margin-bottom: 10%;">
      <div class="col" style="width: 63%;">
        <div class="standout-copy" style="text-align: left; width: 100%;">
          As part of our thinking, we looked beyond traditional mediums and did some interesting collabs to promote the club.
        </div>
      </div>
      <div class="col" style="width: 40%;">
        <img src="/static/images/RHRN_4.jpg" style="max-width: 80%; margin-bottom: -80%; float: right; margin-right: 10%; z-index: 3; position: relative;"/>
      </div>
    </div>

    <div class="mobile-only">
      <div class="standout-copy" style="text-align: left; width: 100%;">
        As part of our thinking, we looked beyond traditional mediums and did some interesting collabs to promote the club.
      </div>
      <div class="spacer-lg"></div>
      <img src="/static/images/RHRN_4.jpg"/>
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/RHRN_7.jpg" />

    <div class="spacer-lg"></div>

    <img src="/static/images/RHRN_8.jpg"/>

    <div class="footer">
      <div class="prev">
        <router-link to="/ca-watch-me">
          <img src="/static/images/prev.svg"/>
        </router-link>
      </div>
      <div class="footer-contact">
        <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
        <span>&copy; {{date}} by Blood UTD</span>
      </div>
      <div class="next">
        <router-link to="/nike-play-gold">
          <img src="/static/images/next.svg"/>
        </router-link>
      </div>
    </div>

  </div>
</template>


<script>
export default {
  name: 'right-here-right-now',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  }
}
</script>

<style lang="scss" scoped>

.right-here-right-now {

/*  .copy container {
    .col-1 {
      width: 55%;
    }
  }
*/
  .no-negative-top-mobile {
    @media(max-width: 680px) {
      
      .col:first-child {
         width: 39% !important;
         z-index: 2 !important;
        img {
          width: 60% !important;
          margin-right: -10% !important;
          margin-top: 10% !important;
        }
      }

      .col:last-child {
        width: 59% !important;
      } 
    }
  }

  .mobile-margin-bottom {
    @media(max-width: 680px) {

      margin-bottom: -5% !important;
    
      .col:last-child {
        img {
          margin-bottom: -310px !important;
        }
      }
    }
  }

}

  .no-desktop {
    @media(max-width: 680px) {
      display: none !important
    }
  }

</style>
